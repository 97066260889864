<template>
  <div class="side__nav tw-px-8 py-8">
    <v-card min-width="300">
      <v-card-text>
        <chosen-product-overview :product-name="productName" :image="short_sleeves" :color="selectedColor" />
      </v-card-text>
    </v-card>
    <v-card max-width="300" class="my-8">
      <v-card-text>
        <shirts-action-buttons @add-text="addText=true" @change="SET_BTN_IN_VIEW" :active="btnInView" />
      </v-card-text>
      <v-divider />
      <v-card-text>
        <v-slide-x-transition>
<!--       <keep-alive>-->
         <component
           @editText="editText"
           :add-text="addText"
           :show-style="showStyle"
           @insertText="insertText"
           @insertImage="insertImage"
           @styleText="styleText"
           @cancel="$emit('cancel')"
             :is="currentComponent"
             :image="short_sleeves"
             :product-name="productName" />
<!--       </keep-alive>-->
        </v-slide-x-transition>
      </v-card-text>
    </v-card>
    <v-card height="88" @click="requestDesignModal = true">
      <request-design-card />
    </v-card>
    <request-design-modal v-model="requestDesignModal" />
  </div>
</template>

<script>
// import shirt from '@/assets/shirt.svg'
import shirt_active from '@/assets/shirt_active.svg'
// import beaker from '@/assets/beaker.svg'
// import beakerActive from '@/assets/beaker_active.svg'
import textIcon from '@/assets/text.svg'
import textIconActive from '@/assets/text_active.svg'
import downloadIcon from '@/assets/download.svg'
import downloadIconActive from '@/assets/download_active.svg'
import short_sleeves from "@/assets/shortsleeve.png"
import ColorPicker from "@/reuseable/ColorPicker";
import Icon from "@/components/icons/Icon";
import ChosenProductOverview from "@/components/build/ChosenProductOverview";
import MerchColorPickerSet from "@/components/build/MerchColorPickerSet";
import {mapMutations, mapState} from "vuex";
import UploadImageForMerch from "@/components/build/UploadImageForMerch";
import NewAddText from "@/components/build/NewAddText";
import NewUploadImageForMerch from "@/components/build/NewUploadImageForMerch";
import ShirtsActionButtons from "@/components/build/ShirtsActionButtons";
import RequestDesignCard from "@/components/build/RequestDesignCard";
import RequestDesignModal from "@/components/build/request_design/RequestDesignModal";
import eventBus from "@/eventBus";

export default {
  name: "BuildSide",
  components: { RequestDesignModal, RequestDesignCard, ShirtsActionButtons, ChosenProductOverview, Icon, ColorPicker},
  data() {
    return {
      short_sleeves,
      selected: 'Merch',
      buildText: false,
      merchColor: {
        marginLeft: 'ml-5',
        paddingRight: 'pr-5'
      },
      addText:false,
      requestDesignModal: false
    }
  },
  props:{
    showStyle:[Boolean]

  },
  created () {
    if (this.$vuetify.breakpoint.mobile) {
      this.SET_BTN_IN_VIEW('')
    }
  },
  mounted() {
    eventBus.$on('request-design', () => this.requestDesignModal = true)
  },
  computed: {
    ...mapState('buildMerch', ['selectedColor', 'btnInView', 'productName']),
    // productName() {
    //   return this.$route.query.product_name || 'Polo Hands-off Tee'
    // },
    currentComponent () {
      const components = {
        Merch: MerchColorPickerSet,
        'Image Upload': NewUploadImageForMerch,
        'Add Text': NewAddText
      }
      return components[this.btnInView] ?? MerchColorPickerSet
    }
  },
  methods: {
    ...mapMutations('buildMerch', ['SET_BTN_IN_VIEW']),
    handleSelected(selected) {
      console.log(selected)
      this.selected = selected;
    },
    showBuildText() {
      this.buildText = true;
      this.selected = '';
    },
    saveBuildText(save_type) {
      if (save_type === 'cancel') {
        this.buildText = false;
        this.selected = 'Add Text'
      } else if (save_type === 'save') {
        this.buildText = false;
        this.selected = 'Add Text'
      }
    },
    insertText(input){
      this.$emit('insertText',input)
    },
    editText(input){
      this.$emit('editText',input)
    },
    insertImage(e){
      console.log(e)
      this.$emit('insertImage',e)
    },
    styleText(style,value){
      console.log('color side')

      this.$emit('styleText',style,value)
    }
  }
}
</script>

<style lang="scss" scoped>

.tab {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  //color: #292929;
  padding-bottom: 1vh;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

}

.active-btn {
  color: #FF7A01;
}

.side-nav {
  width: 20%;
  border-right: 1px solid #EAEAEA;
  padding: 1vh 2vh;
  flex-direction: column;

  @media screen and (max-width: 1440px) {
    width: 26.8%;
  }
}

.v-card {
  box-shadow: 0px 12px 24px rgba(232, 232, 232, 0.4) !important;
  border-radius: 12px !important;
  transition: height 2s ease !important;
}


.merch__title {
  font-size: 12px;
  color: #514A41;
}

.side__nav {
  height: calc(100vh - 64px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    background: transparent;
    margin-top: 5px;
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background: #D1D3DB;
    width: 6px;
    border-radius: 4px;
  }
}
</style>
