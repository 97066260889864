<template>
  <v-row>
    <v-col cols="2" v-for="(_color, i) in colors" :key="i">
      <color-picker @click="$emit('input', $event)" class="tw-cursor-pointer" :color="_color" :active="_color === color" />
    </v-col>
  </v-row>
</template>

<script>
import ColorPicker from "@/reuseable/ColorPicker";
export default {
  name: "SelectColorFromPicker",
  components: { ColorPicker },
  data: () => ({
    color: ''
  }),
  props: {
    colors: {
      type: Array,
      default: () => ([
        '#000',
        '#FFF',
        '#E80000',
        '#FFD706',
        '#23B100',
        '#2250C7',
        '#CE08BA',
        '#48A7FF',
        '#FF7A01',
        '#A4A4A4',
        '#E7E7E7'
      ])
    }
  }
};
</script>

<style scoped>

</style>
