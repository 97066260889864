<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.643 4.93702C22.808 5.30702 21.911 5.55702 20.968 5.67002C21.941 5.08781 22.669 4.17148 23.016 3.09202C22.1019 3.63501 21.1014 4.01721 20.058 4.22202C19.3564 3.47288 18.4271 2.97634 17.4143 2.80949C16.4016 2.64264 15.3621 2.81481 14.4572 3.29927C13.5524 3.78373 12.8328 4.55338 12.4102 5.48872C11.9875 6.42406 11.8855 7.47277 12.12 8.47202C10.2677 8.37901 8.45564 7.89757 6.80144 7.05892C5.14723 6.22028 3.68785 5.04318 2.51801 3.60402C2.11801 4.29402 1.88801 5.09402 1.88801 5.94602C1.88757 6.71301 2.07644 7.46826 2.43789 8.14475C2.79934 8.82124 3.32217 9.39805 3.96001 9.82402C3.22029 9.80048 2.49688 9.6006 1.85001 9.24102V9.30102C1.84994 10.3768 2.22204 11.4194 2.90319 12.252C3.58434 13.0846 4.53258 13.6559 5.58701 13.869C4.9008 14.0547 4.18135 14.0821 3.48301 13.949C3.78051 14.8746 4.36001 15.684 5.14038 16.2639C5.92075 16.8438 6.86293 17.1652 7.83501 17.183C6.18484 18.4784 4.1469 19.1811 2.04901 19.178C1.67739 19.1781 1.30609 19.1564 0.937012 19.113C3.06649 20.4822 5.54535 21.2088 8.07701 21.206C16.647 21.206 21.332 14.108 21.332 7.95202C21.332 7.75202 21.327 7.55002 21.318 7.35002C22.2293 6.69099 23.0159 5.87491 23.641 4.94002L23.643 4.93702Z" fill="black"/>
  </svg>
</template>

<script>
export default {
name: "TwitterIcon"
}
</script>

<style scoped>

</style>