export default {
  mounted () {
    window.addEventListener('beforeunload', this.preventReload)
  },
  methods: {
    preventReload () {
      event.preventDefault()
      event.returnValue = ""
    },
  }
}
