<template>
  <div>
    <h4 class="font-weight-medium my-5 merch__title">MERCH</h4>
    <chosen-product-overview :image="image" :product-name="productName"/>
    <select-color-from-picker @input="setColor" class="my-6" />
    <div>
      <v-btn color="#FFF8EF" depressed rounded :to="{ name: 'Categories' }">CHANGE MERCH</v-btn>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/reuseable/ColorPicker";
import ChosenProductOverview from "@/components/build/ChosenProductOverview";
import {mapMutations, mapState} from "vuex";
import SelectColorFromPicker from "@/components/build/SelectColorFromPicker";
export default {
  name: "MerchColorPickerSet",
  components: { SelectColorFromPicker, ChosenProductOverview, ColorPicker},
  props: {
    image: String,
    productName: String
  },
  computed: {
    ...mapState('buildMerch', ['selectedColor']),
  },
  created() {
    if (this.selectedColor) {
      this.SET_SELECTED_COLOR(this.selectedColor)
    } else {
      const [color] = this.colors
      this.SET_SELECTED_COLOR(color)
    }
  },
  methods: {
    ...mapMutations('buildMerch', ['SET_SELECTED_COLOR']),
    ...mapMutations('buildMerch', ['SET_BTN_IN_VIEW']),

    setColor(event){
      if(this.$vuetify.breakpoint.mobile){
        this.SET_SELECTED_COLOR(event)
      this.SET_BTN_IN_VIEW('')
      }
      else{
        this.SET_SELECTED_COLOR(event)

      }
    }
  }
}
</script>

<style scoped>
.v-btn {
  color: #FF7A01;
  font-size: 14px;
  font-weight: 700;
}
</style>
