<template>
  <div class="export-canvas-drawer">
    <div class="close__drawer__btn" v-if="drawer">
      <v-btn icon @click="[setDrawer(false)]">
        <vue-feather type="x" />
      </v-btn>
    </div>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        width="586"
        right
        bottom
        temporary>
      <div class="drawer__content px-16 py-8">
        <div>
          <v-text-field
              v-model="title"
              autofocus
              :outlined="$vuetify.breakpoint.mobile"
              label="Build Title"
          />
        </div>
        <div class="d-flex justify-center" v-if="!$vuetify.breakpoint.mobile">
          <div>
            <v-img
                :src="canvasImageURL"
                width="400"
                height="465"
            />
          </div>
        </div>
        <div class="my-5">
          <div class="share__with__friends d-flex justify-space-between align-center">
            <div>
              <span>Share as Image</span>
            </div>
            <div class="d-flex my-3">
              <div v-for="(social, i) in socialShares" class="mr-3" :key="i">
                <v-btn icon>
                  <icon :type="social" />
                </v-btn>
              </div>
            </div>
          </div>
          <div class="my-10 d-flex actual__decisions">
            <div class="mr-3">
              <v-btn color="primary" :loading="loading" @click="saveForLater" rounded outlined>Save for later</v-btn>
            </div>
            <div>
              <v-btn color="primary" depressed rounded class="text-capitalize" @click="addToCart">Proceed</v-btn>
            </div>
          </div>
          <h5>Export as</h5>
          <div class="d-flex my-3">
            <div v-for="(btn, i) in exportButtons" :key="i" class="mr-4">
              <v-btn @click="[exportAs(btn)]" outlined small>
                <vue-feather type="download" size="16" class="mr-1" />
                <span>{{ btn }}</span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import Icon from "@/components/icons/Icon";
import {saveDesign} from "@/services/user-profile";

export default {
  name: "ExportCanvasDrawer",
  components: {Icon},
  data: () => ({
    exportButtons: ['JPEG', 'PNG', 'SVG'],
    socialShares: ['twitter', 'whatsapp', 'facebook'],
    count: 12,
    loading:false,
    cloud_front_url:''
  }),
  computed: {
    ...mapState('buildMerch', ['designTitle', 'exportCanvasDrawer', 'canvasImageURL', 'productName','canvasFrontJson','canvasBackJson']),
    ...mapGetters('buildMerch', ['selectedShirt','selectedBackShirt']),
    drawer: {
      get () {
        return this.exportCanvasDrawer
      },
      set (drawer) {
        this.setDrawer(drawer)
      }
    },
    title: {
      get () {
        return this.designTitle
      },
      set (title) {
        this.UPDATE_STATE_PROPERTY({prop: 'designTitle', value: title})
      }
    }
  },
  methods: {
    ...mapMutations('buildMerch', ['UPDATE_STATE_PROPERTY']),
    setDrawer (drawer) {
      this.UPDATE_STATE_PROPERTY({prop: 'exportCanvasDrawer', value: drawer})
    },
    exportAs (format) {
      console.log(format)
    },
    addToCart () {
      // localStorage.setItem('buildImg',this.canvasImageURL)
      let front = {
        front: this.canvasFrontJson,
        selectedShirt : this.selectedShirt,
        title:this.designTitle
      }
      let back
      if(Object.keys(this.canvasBackJson).length){
         back = {
          back: this.canvasBackJson,
          selectedShirt : this.selectedBackShirt,
          title:this.designTitle
        }
      }

      localStorage.setItem('frontBuildJson',JSON.stringify(front))
      localStorage.setItem('backBuildJson',JSON.stringify(back))

      // this.$store.commit('buildMerch/SET_ALL_STATE')
      this.$router.push({ name: 'AddToCart', query: { productName: this.productName, title: this.designTitle } })
    },
    uploadToCloudinary(file){
      this.loading = true
      const fileData = new FormData();
      fileData.append('file', file);
      fileData.append('upload_preset', 'kneekpreset'); // upload preset
      fileData.append('tags', 'xxxxxx'); // optional

      fetch('https://api.cloudinary.com/v1_1/spacehubpictures/auto/upload', {
        method: 'post',
        body: fileData
      }).then(response => response.json())
        .then(data => {
            this.cloud_front_url = data.url
            // console.log('Success:', data)
          this.callSaveDesignEndpoint()
            this.loading = false
          }
          )
        .catch(err => console.error('Error:', err));
    },
    async saveForLater () {
      localStorage.setItem('buildImg',this.canvasImageURL)
      this.$store.commit('buildMerch/SET_ALL_STATE')
      this.uploadToCloudinary(this.canvasImageURL)
    },

    callSaveDesignEndpoint(){
      let designs = JSON.parse(localStorage.getItem('allDesignProps'))
      let data = {
        selected_product: 1,
        merch_name: designs.designTitle,
        "color": "",
        // text: designs.customText,
        text: "coding is lovely",
        text_font: designs.fontFamily,
        letter_spacing: designs.fontLetterSpacing,
        font_size: designs.fontSize,
        rotation_angle: designs.letterAngle,
        merch_data: {
          back: "",
          color: "white",
          // front: designs.canvasImageURL,
          front:this.cloud_front_url,
          default: "true",
          color_code: designs.textColor
        },
      }
        // console.log(data);
        saveDesign(data).then(
          res => {
            console.log(res)
          }
        ).catch(
          err => {
            console.log(err)
          }
        )
    }
  }
}
</script>

<style scoped lang="scss">
.export-canvas-drawer {

  .close__drawer__btn {
    position: fixed;
    left: calc(100vw - 660px);
    top: 10px;
    z-index: 8;

    .v-btn {
      background: white;
    }
  }

  .actual__decisions {
    .v-btn {
      border-width: 2px;
    }
  }

  .share__with__friends {
    width: 75%;
  }

}
</style>
