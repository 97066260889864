<template>
  <div>
    <build-header @on-next="onNext"/>
    <div style="display: flex; width: 100vw;">
      <div :class="{'d-none': $vuetify.breakpoint.mobile}" class="side__nav__toggle">
        <build-side @cancel="cancel"  @editText="editText" @insertImage="insertImage" @insertText="insertText" @styleText="styleText" />
      </div>
      <NewMerchPlayground @cancel="cancel" @editText="editText" @insertImage="insertImage" @insertText="insertText" @styleText="styleText" :shirtContainerStyles="shirtContainerStyles" :zoomPercentage="zoomPercentage"  @adjusted="adjustZoomLevel" @undo="undo" @redo="redo"/>

    </div>
  <mobile-bottom-navigation/>
  </div>
</template>

<script>
import BuildSide from "@/components/build/BuildSide";
import { fabric } from "fabric";
import { mapMutations, mapState } from "vuex";
import NewMerchPlayground from "@/components/build/NewMerchPlayground";
import   'fabric-history'
import BuildHeader from "@/components/build/BuildHeader";
import MobileBottomNavigation from "@/reuseable/MobileBottomNavigation";
import PreventsReload from "@/mixins/PreventsReload";

const LOWEST_ZOOM_LEVEL = 100

export default {
  name: "newBuild",
  mixins: [PreventsReload],
  components: { MobileBottomNavigation, BuildHeader, NewMerchPlayground, BuildSide,  },
  data() {
    return {
      canvas2: "",
      color:"",
      showStyle:false,
      zoomPercentage: LOWEST_ZOOM_LEVEL,
      canvasback:''
    };
  },
  computed: {
    ...mapState('buildMerch',
      [
        'selectedColor',
        'customText',
        'isBack'

      ]),
    shirtContainerStyles () {
      const _this = this
      // immediately invoked function to determine scale transform of shirt
      const transform = (function () {
        const { zoomPercentage } = _this
        let scale = 1
        if (zoomPercentage) {
          scale = (zoomPercentage / 100) * scale
        }
        return `scale(${scale})`
      }())

      return {
        transform,
      }
    },
  },
  mounted() {
    // window.addEventListener('beforeunload', this.preventReload)
    document.body.style.overflow = "hidden"
    let _this = this
    let front = document.getElementById('canvas2')
    let back = document.getElementById('canvasback')
    this.canvas2 = new fabric.Canvas(front);
    this.canvasback = new fabric.Canvas(back);
    let deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE" +
      " svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' " +
      "id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px'" +
      " y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle" +
      " style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162'" +
      " y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;'" +
      " width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' " +
      "style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

    // this.canvas2.on('object:selected',e=>{ this.onObjectSelected(e)});
    let deleteImg = document.createElement('img');
    deleteImg.src = deleteIcon;
    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -16,
      offsetX: 16,
      cursorStyle: 'pointer',
      mouseUpHandler: this.deleteObject,
      render: this.renderIcon(deleteImg),
      cornerSize: 24
    });
    fabric.Object.prototype.cornerStyle = '';

    this.canvas2.on('before:selection:cleared', function() {
      _this.UPDATE_STATE_PROPERTY({prop: 'showStyle', value: false})

    });
    this.canvasback.on('before:selection:cleared', function() {
      _this.UPDATE_STATE_PROPERTY({prop: 'showStyle', value: false})

    });
    this.canvas2.on('mouse:down', e => {
      this.onObjectSelected(e)
      // console.log(canvas.getActiveObject().metaData?.id);
    });
    this.canvasback.on('mouse:down', e => {
      this.onObjectSelected(e)
      // console.log(canvas.getActiveObject().metaData?.id);
    });

    // console.log(this.canvas2);
    // this.canvas2.on('object:selected', this.onObjectSelected);
    //
    // this.canvas2.on('before:selection:cleared', function() {
    //   document.getElementById("textMenu").className = "hideOperations";
    // });
  },
  methods: {
    ...mapMutations('buildMerch', ['UPDATE_STATE_PROPERTY']),
    renderIcon(icon) {
  return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    let size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(icon, -size/2, -size/2, size, size);
    ctx.restore();
  }
},
    deleteObject(eventData, transform) {
      console.log('delete')
      let target = transform.target;
      let canvas = target.canvas;
      canvas.remove(target);
      canvas.requestRenderAll();
    },
    insertText(input) {
      let text = new fabric.IText(input, { left: 40, top: 100 });
      if(this.isBack){
        this.canvasback.setActiveObject(text)
        text.set({'borderColor':'#fbb802','cornerColor':'#fbb802'})
        this.canvasback.add(text);
        this.canvasBackDetails.text = input
      }
      else {
        this.canvas2.add(text);
        this.canvas2.setActiveObject(text)
        console.log(this.canvas2.getActiveObject())
        text.set({'borderColor':'#fbb802','cornerColor':'#fbb802'})
        this.canvas2.text = input
      }

      this.text = "";
      this.UPDATE_STATE_PROPERTY({prop: 'showStyle', value: true})

    },
    insertImage(e) {
      let _this = this;
      let reader = new FileReader();
      reader.onload = function(event) {
        let imgObj = new Image();
        imgObj.src = event.target.result;
        imgObj.onload = function() {
          let image = new fabric.Image(imgObj);
          image.set({
            angle: 0,
            padding: 10,
            cornersize: 10,
            scaleX: _this.canvas2.width / (image.width + 400),
            scaleY: _this.canvas2.height / (image.height + 400)
          });
          console.log(_this.canvas2.width, image.width);
          if(_this.isBack) {
            _this.canvasback.centerObject(image);
            _this.canvasback.add(image);
            _this.canvasback.setActiveObject(image)
            image.set({'borderColor':'#fbb802','cornerColor':'#fbb802'})

            _this.canvasback.renderAll();

          }
          else {
            _this.canvas2.centerObject(image);
            _this.canvas2.add(image);
            _this.canvas2.setActiveObject(image)
            image.set({'borderColor':'#fbb802','cornerColor':'#fbb802'})

            _this.canvas2.renderAll();

          }
        };
      };
      // alert(_this.canvas2.getActiveObject())
      reader.readAsDataURL(e.target.files[0]);
    },
    styleText(style,value){
      if(this.isBack) {
        let text = this.canvasback.getActiveObject()
        if(text) {
          text.set({ [style]: value });
          this.canvasback.renderAll()
        }
      }
      else {
        let text = this.canvas2.getActiveObject()
        if(text) {
          text.set({ [style]: value });
          this.canvas2.renderAll()
        }
      }

    },
   editText(value) {

  let cValue = document.getElementById('edit-text').value;
// console.log(this.canvas2.getActiveObject(),{cValue,value})
  // This is the imp line
     if(this.isBack) {
       console.log(this.canvasback.getActiveObject())
       if (!this.canvasback.getActiveObject()) {
         let text = new fabric.IText(value, { left: 40, top: 100 });
         this.canvasback.setActiveObject(text)
         text.set({'borderColor':'#fbb802','cornerColor':'#fbb802'})
         this.canvasback.add(text);
       } else {
         this.canvasback.getActiveObject().set("text", value);
         this.canvasback.renderAll();
       }
     }
     else {
       if (!this.canvas2.getActiveObject()) {
         let text = new fabric.IText(value, { left: 40, top: 100 });
         this.canvas2.setActiveObject(text)
         text.set({'borderColor':'#fbb802','cornerColor':'#fbb802'})
         this.canvas2.add(text);
       } else {
         this.canvas2.getActiveObject().set("text", value);
         this.canvas2.renderAll();
       }
     }
},
     onObjectSelected(e) {
      let _this=this
      if ((e.target&&e.target.get('type')) === "i-text") {

        _this.showStyle = true
        console.log('show after', _this.showStyle)
        this.UPDATE_STATE_PROPERTY({prop: 'showStyle', value: true})

      } else {
        // do nothing.
      }
      return _this.showStyle
  },
    undo() {
      if(this.isBack) {
        this.canvasback.undo()
      }
      else {
        this.canvas2.undo()

      }
    },
    redo() {
      if(this.isBack) {
        this.canvasback.redo()

      }
      else {
        this.canvas2.redo()

      }
    },
    adjustZoomLevel (type) {
      let _this = this
      const { zoomPercentage } = this
      const HIGHEST_ZOOM_LEVEL = 140
      const SIZE_INTERVAL = 10
      const types = {
        'increase': () => {
          if (zoomPercentage) {
            if (zoomPercentage === HIGHEST_ZOOM_LEVEL)
              return
            return this.zoomPercentage += SIZE_INTERVAL
          }
          return this.zoomPercentage = LOWEST_ZOOM_LEVEL + SIZE_INTERVAL
        },
        'decrease': () => {
          if (zoomPercentage) {
            if (zoomPercentage === LOWEST_ZOOM_LEVEL)
              return
            return this.zoomPercentage -= SIZE_INTERVAL
          }
          return this.zoomPercentage = this.zoomPercentage - SIZE_INTERVAL
        }
      }
      _this.UPDATE_STATE_PROPERTY({prop: 'zoomValue', value: zoomPercentage})

      types[type]?.()
    },
    // preventReload () {
    //   event.preventDefault()
    //   event.returnValue = ""
    // },
    onNext(){
      let jsonObject = JSON.stringify(this.canvas2)
      let jsonBackObject = JSON.stringify(this.canvasback)

      this.UPDATE_STATE_PROPERTY({prop: 'canvasFrontJson', value: jsonObject})
      this.UPDATE_STATE_PROPERTY({prop: 'canvasBackJson', value: jsonBackObject})


      // let obj =    this.canvas2.toJSON()
      // const size = Buffer.byteLength(JSON.stringify(obj))
      // console.log(size)
    },
    cancel() {
      if(this.isBack) {
        let text = this.canvasback.getActiveObject();
        text.remove()
        this.canvasback.renderAll();
      }
      else {

        this.canvas2.remove(this.canvas2.getActiveObject());
        this.canvas2.renderAll();

      }
    },




  },
  beforeDestroy() {
    document.body.style.overflow = "initial"
    window.removeEventListener('beforeunload', this.preventReload)
  },

};
</script>

<style scoped>

</style>
