<template>
  <div>
    <div class="d-flex justify-center">
      <div class="d-flex align-center justify-center pa-3 toggle__orientation__button " v-if="!$vuetify.breakpoint.mobile" @click="show">
        <div  v-if="isBackMerch">
          <front-merch :image-width="28" :image-height="33" :merch-height="'tw-h-8'" :merch-width="'tw-w-7'" :width="'10px'" :height="'12px'" />
        </div>
        <div v-else>
          <back-merch :image-width="28" :image-height="33" :merch-height="'tw-h-8'" :merch-width="'tw-w-7'" :width="'10px'" :height="'12px'"/>
        </div>
        <div class="flex-grow-1 ml-1">SHOW {{isBackMerch?'FRONT':'BACK'}}</div>
      </div>
      <v-btn icon v-else @click="show">
        <vue-feather type="refresh-cw" />
      </v-btn>
    </div>
  </div>


</template>

<script>
import sampleShirt from '@/assets/shirts/small_sample_shirt.svg'
import { mapState } from "vuex";
import FrontMerch from "@/components/build/FrontMerch";
import BackMerch from "@/components/build/BackMerch";
export default {
  name: "ToggleShirtOrientationButton",
  components: { BackMerch, FrontMerch },
  data: () => ({
    sampleShirt
  }),
  props:{
    backCanvas:[Object],
    isBackMerch:[Boolean]
  },
  computed: {
    ...mapState('buildMerch',
      [
        'selectedColor',
        'customText',
        'fontFamily',
        'fontSize',
        'fontLetterSpacing',
        'image',
        'textColor',
        'letterAngle',
        'canvas',
        'selectedText',
        'updatedText',
        'isBack'
      ]),
  },
  methods:{

    show(){
      this.$emit('toggleMerch')
    },
  }
}
</script>

<style lang="scss" scoped>
.toggle__orientation__button {
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-shadow: 0px 12px 24px rgba(232, 232, 232, 0.4);
  border-radius: 8px;
  //width: 168px;
  //min-height: 50px;
  //height: 55px;
  overflow-y: hidden;
  cursor: pointer;

  div.flex-grow-1 {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
  }
}

.v-btn {
  background: white;
}
</style>
