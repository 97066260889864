<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.40869 7.59534V4.1499H19.0877V7.59534" :stroke="active ? '#FF7A01' : '#514A41'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.2485 4.1499V19.9415" :stroke="active ? '#FF7A01' : '#514A41'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.88525 19.9414H15.6119" :stroke="active ? '#FF7A01' : '#514A41'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "TextIcon",
  props: {
    active: Boolean
  }
}
</script>

<style scoped>

</style>