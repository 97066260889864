<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.125 16.854C4.91586 16.854 3.125 15.0631 3.125 12.854C3.125 10.947 4.45957 9.35161 6.24571 8.95096C6.16669 8.59795 6.125 8.23085 6.125 7.854C6.125 5.09258 8.36358 2.854 11.125 2.854C13.5443 2.854 15.5623 4.57225 16.0252 6.85498C16.0584 6.85433 16.0916 6.854 16.125 6.854C18.8864 6.854 21.125 9.09258 21.125 11.854C21.125 14.273 19.4072 16.2907 17.125 16.754M15.125 13.854L12.125 10.854M12.125 10.854L9.125 13.854M12.125 10.854L12.125 22.854" stroke="#514A41" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
name: "UploadIconStyled"
}
</script>

<style scoped>

</style>