<template>
  <div class="my-4">
    <div v-if="!image" ref="dragArea" class="drag__area d-flex justify-center align-center" @drop.prevent="uploadImage">
      <div class="d-flex flex-column align-center justify-center">
        <upload-icon-styled/>
        <span>
        Drag & Drop
        <br/>
        or <span class="browse__file tw-cursor-pointer" @click="openExplorer">Browse Files</span>
      </span>
      </div>
    </div>
    <div v-else-if="image && showPreview">
      <v-img
        class="rounded"
        :src="image"
        width="265"
        height="160"
        />
    </div>
    <input type="file" id="imgLoader" class="d-none" @change="uploadImage" ref="file__uploader">
  </div>
</template>

<script>
import UploadIconStyled from "@/components/icons/UploadIconStyled";
import eventBus from "@/eventBus";
import { fabric } from "fabric";
import { mapMutations } from "vuex";

export default {
  name: "DragAndDropUploader",
  components: {UploadIconStyled},
  data: () => ({
    image: ''
  }),
  props: {
    showPreview: {
      type: Boolean,
    },
    handleChange: Function
  },
  mounted() {
    eventBus.$on('replace', this.openExplorer)
  },
  methods: {
    ...mapMutations('buildMerch', ['UPDATE_STATE_PROPERTY', 'INSERT_NEW_SIZE', 'SET_CANVAS_IMAGE_URL','SET_BTN_IN_VIEW']),
    uploadImage(e) {
      this.$emit('change',e)
      let file
      if (e.type === 'drop') {
        [file] = e.dataTransfer.files
      } else [file] = e.target.files
      const fr = new FileReader()
      fr.onload = ({target: {result}}) => {
        this.image = result
        if (this.handleChange && typeof this.handleChange === 'function') {
          this.handleChange(file)
        }
        else {
          this.UPDATE_STATE_PROPERTY({prop: 'image', value: this.image});
        }

        // this.$emit('change')
      }
      fr.readAsDataURL(file)
      if (!this.handleChange) {
        this.SET_BTN_IN_VIEW('Merch')
      }

    },
    openExplorer () {
      console.log(this.$refs)
      this.$refs.file__uploader.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.drag__area {
  border: 1px dashed #FF7A01;
  border-radius: 12px;
  font-size: 14px;
  line-height: 18.23px;
  //width: 265px;
  height: 160px;

  span {
    font-weight: 500;

    .browse__file {
      color: #FF7A01;
    }
  }
}
</style>
