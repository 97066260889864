<template>
  <svg fill="none" height="25" viewBox="0 0 25 25" width="25" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1497_1264)">
      <path
          d="M7.55758 4.14795L3.27295 8.0385L5.54938 10.6785L7.55758 8.86186V20.7153H18.5318V8.86186L20.6472 10.6785L22.9059 8.0385L18.5318 4.14795H15.5997L14.6249 5.12273C13.8218 5.92584 12.5119 5.89978 11.7413 5.06537L10.8942 4.14795H7.55758Z"
          :stroke="active ? '#FF7A01' : '#514A41'" stroke-width="2"/>
    </g>
    <defs>
      <clipPath id="clip0_1497_1264">
        <rect fill="white" height="24" transform="translate(0.894043 0.212402)" width="24"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "MerchIcon",
  props: {
    active: Boolean,
  }
}
</script>

<style scoped>

</style>