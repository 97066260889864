<template>
  <v-row>
    <v-col v-for="(btn, i) in buttons" :key="i" cols="4">
      <div class="d-flex flex-column justify-center align-center tw-cursor-pointer" @click="[makeBtnActive(btn.name)]">
        <icon :active="active === btn.name" :type="btn.icon"/>
        <span :class="{'btn__name--active': active === btn.name}" class="text-center btn__name">{{ btn.name }}</span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import shirt_active from "@/assets/shirt_active.svg";
import textIconActive from "@/assets/text_active.svg";
import downloadIconActive from "@/assets/download_active.svg";
import Icon from "@/components/icons/Icon";
import { mapMutations } from "vuex";

export default {
  name: "ShirtsActionButtons",
  components: {Icon},
  data: () => ({
    buttons: [
      {
        icon: 'merch',
        name: 'Merch',
      },
      {
        icon: 'text',
        name: 'Add Text',
      },
      {
        icon: 'upload',
        name: 'Image Upload',
      },
    ],
  }),
  props: {
    active: String
  },
  methods: {
    ...mapMutations('buildMerch', ['UPDATE_STATE_PROPERTY']),

    makeBtnActive(name) {
      if(name==='Add Text'){

        this.$emit('add-text')
        // this.UPDATE_STATE_PROPERTY({prop: 'showStyle', value: true})

      }
      this.$emit('change', name)
    }
  }
}
</script>

<style scoped lang="scss">
.btn__name {
  font-size: 10px;
  line-height: 13.02px;
  font-weight: 400;

  &--active {
    color: #FF7A01 !important;
  }
}
</style>
