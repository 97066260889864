<template>
  <div class="request-design-card-wrap tw-w-full tw-h-full pa-4">
    <div class="d-flex mb-2 justify-space-between">
      <div class="d-flex align-center">
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.4625 13.0873C16.2298 12.8546 15.9335 12.696 15.6108 12.6315L13.6212 12.2336C12.5309 12.0155 11.399 12.1671 10.4045 12.6643L10.1401 12.7966C9.14556 13.2938 8.01369 13.4454 6.9234 13.2273L5.31361 12.9054C4.76718 12.7961 4.20228 12.9671 3.80824 13.3612M6.93896 3.56378H13.6056L12.7723 4.39712V8.70676C12.7723 9.14879 12.9479 9.57271 13.2605 9.88527L17.4271 14.0519C18.4771 15.1019 17.7334 16.8971 16.2486 16.8971H4.29598C2.81114 16.8971 2.06753 15.1019 3.11747 14.0519L7.28414 9.88527C7.5967 9.57271 7.7723 9.14879 7.7723 8.70676V4.39712L6.93896 3.56378Z" stroke="#4B4DED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span class="ml-2">Request a Design</span>
      </div>
      <div class="d-flex align-center">
        <svg width="41" height="20" viewBox="0 0 41 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.272461" y="0.730469" width="40" height="19" rx="9.5" fill="#9AF97C"/>
          <path d="M6.83646 14.7305V13.5785H7.96446V7.48247H6.83646V6.33047H11.0965C11.6005 6.33047 12.0405 6.41847 12.4165 6.59447C12.7925 6.76247 13.0845 7.00647 13.2925 7.32647C13.5085 7.63847 13.6165 8.01047 13.6165 8.44247V8.56247C13.6165 8.94647 13.5445 9.26247 13.4005 9.51047C13.2565 9.75847 13.0805 9.95047 12.8725 10.0865C12.6725 10.2225 12.4805 10.3185 12.2965 10.3745V10.5665C12.4805 10.6145 12.6765 10.7105 12.8845 10.8545C13.1005 10.9905 13.2805 11.1825 13.4245 11.4305C13.5765 11.6785 13.6525 12.0025 13.6525 12.4025V12.5225C13.6525 12.9865 13.5445 13.3825 13.3285 13.7105C13.1125 14.0385 12.8125 14.2905 12.4285 14.4665C12.0525 14.6425 11.6165 14.7305 11.1205 14.7305H6.83646ZM9.27246 13.5545H10.9645C11.3965 13.5545 11.7365 13.4505 11.9845 13.2425C12.2325 13.0345 12.3565 12.7465 12.3565 12.3785V12.2705C12.3565 11.9025 12.2325 11.6145 11.9845 11.4065C11.7445 11.1985 11.4045 11.0945 10.9645 11.0945H9.27246V13.5545ZM9.27246 9.90647H10.9645C11.3725 9.90647 11.7005 9.80647 11.9485 9.60647C12.1965 9.39847 12.3205 9.11847 12.3205 8.76647V8.64647C12.3205 8.29447 12.1965 8.01847 11.9485 7.81847C11.7085 7.61047 11.3805 7.50647 10.9645 7.50647H9.27246V9.90647ZM14.8655 14.7305V6.33047H20.1575V7.50647H16.1615V9.91847H19.8215V11.0945H16.1615V13.5545H20.2175V14.7305H14.8655ZM23.3326 14.7305V7.50647H20.7526V6.33047H27.2086V7.50647H24.6286V14.7305H23.3326ZM26.6873 14.7305L29.0873 6.33047H31.3673L33.7553 14.7305H32.4233L31.8713 12.7505H28.5833L28.0313 14.7305H26.6873ZM28.9073 11.5505H31.5473L30.3233 7.13447H30.1313L28.9073 11.5505Z" fill="#00050D"/>
        </svg>
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5654 3.52336C10.9559 3.13284 11.589 3.13284 11.9796 3.52336L17.9796 9.52336C18.3701 9.91389 18.3701 10.5471 17.9796 10.9376L11.9796 16.9376C11.589 17.3281 10.9559 17.3281 10.5654 16.9376C10.1748 16.5471 10.1748 15.9139 10.5654 15.5234L14.8582 11.2305L3.27246 11.2305C2.72018 11.2305 2.27246 10.7828 2.27246 10.2305C2.27246 9.67818 2.72018 9.23047 3.27246 9.23047H14.8582L10.5654 4.93758C10.1748 4.54705 10.1748 3.91389 10.5654 3.52336Z" fill="#DDDDDD"/>
        </svg>
      </div>
    </div>
    <p>Tell us what you want on your merch and let us create the design for you.</p>
  </div>
</template>

<script>
export default {
  name: "RequestDesignCard",
  created() {
    console.log('being used')
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;

    /* Brown/500 */

    color: #514A41;
    width: 269px;
  }

  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;

    /* Accent/Lurple */

    color: #4B4DED;
  }
}
</style>
