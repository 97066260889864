<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.52148 16.6982L4.52148 17.6982C4.52148 19.3551 5.86463 20.6982 7.52148 20.6982L17.5215 20.6982C19.1783 20.6982 20.5215 19.3551 20.5215 17.6982L20.5215 16.6982M16.5215 8.69824L12.5215 4.69824M12.5215 4.69824L8.52148 8.69824M12.5215 4.69824L12.5215 16.6982"
          :stroke="active ? '#FF7A01' : '#514A41'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "UploadIcon",
  props: {
    active: Boolean
  }
}
</script>

<style scoped>

</style>
