<template>
  <div :class="[{'active': active}, 'pa-3 d-flex justify-center align-center']" :style="{width: `${+size + 12}px`, height: `${+size + 12}px`}">
    <div>
      <div :style="{background: color, width: `${size}px`, height: `${size}px`, border: getBorder}" @click="$emit('click', color)" class="circle d-flex justify-center align-center">
        <svg v-if="showMark && active" width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.26416 5.67269L4.73009 9.13872L12.1911 1.67773" :stroke="getStrokeColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorPicker",
  data: () => ({
  //
  }),
  computed: {
    getStrokeColor () {
      const { color } = this
      if (color !== 'white' && color.toLowerCase() !== '#fff' && color.toLowerCase() !== '#ffffff') {
        return '#fff'
      }
      return '#000'
    },
    getBorder () {
      const { color } = this
      if (color !== 'white' && color.toLowerCase() !== '#fff' && color.toLowerCase() !== '#ffffff') {
        return '1px solid ' + color
      }
      return '1px solid #EAEAEA'
    }
  },
  props: {
    color: String,
    size: {
      type: [Number, String],
      default: '25.6'
    },
    active: Boolean,
    showMark: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped lang="scss">
.circle {
  border-radius: 100%;
}

.active {
  border: 2px solid orange;
  border-radius: 100%;
}
</style>