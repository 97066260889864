<template>
  <div class="d-flex align-center">
    <div class="image__background pa-2 mr-4 rounded">
      <v-img :src="image" height="54" width="54"/>
    </div>
    <div>
      <span class="product__name-sm">{{ productName }}</span>
      <color-picker v-if="color" :color="color" :show-mark="false" active size="20"/>
    </div>
  </div>
</template>

<script>
import ColorPicker from "@/reuseable/ColorPicker";
export default {
  name: "ChosenProductOverview",
  components: {ColorPicker},
  props: {
    color: String,
    image: String,
    productName: String
  }
}
</script>

<style scoped lang="scss">
.product__name-sm {
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #514A41;
  letter-spacing: -0.02em;
}

.image__background {
  background: #FBFBFB;
}
</style>
