<template>
  <merch-icon v-if="type === 'merch'" :active="active"/>
  <text-icon v-else-if="type === 'text'" :active="active"/>
  <download-icon v-else-if="type === 'download'" :active="active" />
  <facebook-icon v-else-if="type === 'facebook'" />
  <whats-app-icon v-else-if="type === 'whatsapp'" />
  <twitter-icon v-else-if="type === 'twitter'" />
  <upload-icon v-else-if="type === 'upload'" :active="active"/>
</template>

<script>
import MerchIcon from "@/components/icons/MerchIcon";
import TextIcon from "@/components/icons/TextIcon";
import DownloadIcon from "@/components/icons/DownloadIcon";
import FacebookIcon from "@/components/icons/FacebookIcon";
import WhatsAppIcon from "@/components/icons/WhatsAppIcon";
import TwitterIcon from "@/components/icons/TwitterIcon";
import UploadIcon from "@/components/icons/UploadIcon"

export default {
  name: "Icon",
  components: {TwitterIcon, WhatsAppIcon, FacebookIcon, DownloadIcon, TextIcon, MerchIcon, UploadIcon},
  props: {
    type: String,
    active: Boolean
  }
}
</script>

<style scoped>

</style>
