<template>
  <div class="mt-8 mb-4">
    <div class="tw-flex align-center zoom-container py-3 tw-flex-col sm:tw-flex-row">
      <v-btn class="sm:mb-0" color="white" fab x-small @click="[adjustZoomLevel('decrease')]">
        <vue-feather type="minus"/>
      </v-btn>
      <span class="flex-grow-1 text-center sm:tw-block tw-hidden">{{ zoomPercentage || 100 }}%</span>
      <v-btn class="sm:mb-0" color="white" fab x-small @click="[adjustZoomLevel('increase')]">
        <vue-feather type="plus"/>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ZoomButtons",
  methods: {
    adjustZoomLevel (type) {
      this.$emit('adjusted', type)
    }
  },
  props: {
    zoomPercentage: Number
  },
  computed: {
    ...mapState('buildMerch', ['zoomValue']),
  }
}
</script>

<style scoped>

</style>
