<template>
  <div class="bottom__navigation d-flex align-center" v-if="$vuetify.breakpoint.mobile">
    <shirts-action-buttons   @add-text="addText=true"  :active="btnInView" @change="SET_BTN_IN_VIEW"/>
  </div>
</template>

<script>
import ShirtsActionButtons from "@/components/build/ShirtsActionButtons";
import {mapMutations, mapState} from "vuex";
export default {
  name: "MobileBottomNavigation",
  components: {ShirtsActionButtons},
  data: () => ({
    addText:false
  }),
  computed: {
    ...mapState('buildMerch', ['btnInView'])
  },
  methods: {
    ...mapMutations('buildMerch', ['SET_BTN_IN_VIEW'])
  }
}
</script>

<style scoped lang="scss">
.bottom__navigation {
  max-width: 100%;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: white;
  height: 64px;
}
</style>
