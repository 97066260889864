import { render, staticRenderFns } from "./TextIcon.vue?vue&type=template&id=15115198&scoped=true&"
import script from "./TextIcon.vue?vue&type=script&lang=js&"
export * from "./TextIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15115198",
  null
  
)

export default component.exports