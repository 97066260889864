<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.1602 6.84C17.2802 2.4 11.4002 1.08 6.84022 3.84C2.40022 6.6 0.960224 12.6 3.84022 17.04L4.08022 17.4L3.12022 21L6.72022 20.04L7.08022 20.28C8.64022 21.12 10.3202 21.6 12.0002 21.6C13.8002 21.6 15.6002 21.12 17.1602 20.16C21.6002 17.28 22.9202 11.4 20.1602 6.84ZM17.6402 16.08C17.1602 16.8 16.5602 17.28 15.7202 17.4C15.2402 17.4 14.6402 17.64 12.2402 16.68C10.2002 15.72 8.52022 14.16 7.32022 12.36C6.60022 11.52 6.24022 10.44 6.12022 9.36C6.12022 8.4 6.48022 7.56 7.08022 6.96C7.32022 6.72 7.56022 6.6 7.80022 6.6H8.40022C8.64022 6.6 8.88022 6.6 9.00022 7.08C9.24022 7.68 9.84022 9.12 9.84022 9.24C9.96022 9.36 9.96022 9.6 9.84022 9.72C9.96022 9.96 9.84022 10.2 9.72022 10.32C9.60022 10.44 9.48022 10.68 9.36022 10.8C9.12022 10.92 9.00022 11.16 9.12022 11.4C9.60022 12.12 10.2002 12.84 10.8002 13.44C11.5202 14.04 12.2402 14.52 13.0802 14.88C13.3202 15 13.5602 15 13.6802 14.76C13.8002 14.52 14.4002 13.92 14.6402 13.68C14.8802 13.44 15.0002 13.44 15.2402 13.56L17.1602 14.52C17.4002 14.64 17.6402 14.76 17.7602 14.88C17.8802 15.24 17.8802 15.72 17.6402 16.08Z" fill="black"/>
  </svg>
</template>

<script>
export default {
name: "WhatsAppIcon"
}
</script>

<style scoped>

</style>