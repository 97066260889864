<template>
  <div>
    <h4 class="upload__title">Upload your design</h4>
    <drag-and-drop-uploader @change="updateFile" />
    <div v-if="!image">
      <span class="accepted__types">
        Accepted File Types:
        <br />
        JPG, JPEG, PNG, BMP, PDF - 5MB Max.
      </span>
    </div>
<!--    <div v-else>-->
<!--      <v-btn rounded outlined color="primary" @click="replace">Replace</v-btn>-->
<!--    </div>-->
  </div>
</template>

<script>
import DragAndDropUploader from "@/components/build/DragAndDropUploader";
import {mapMutations, mapState} from "vuex";
import eventBus from "@/eventBus";
export default {
name: "NewUploadImageForMerch",
  components: {DragAndDropUploader},
  computed: {
    ...mapState('buildMerch', ['image']),
  },
  methods: {
    ...mapMutations('buildMerch', ['UPDATE_STATE_PROPERTY']),
    updateFile (e) {
      console.log('insert Image')
     this.$emit('insertImage',e)
    },
    replace () {
      eventBus.$emit('replace')
    }
  }
}
</script>

<style scoped lang="scss">
.upload__title {
  color: #292929;
  font-weight: 500;
  line-height: 20.83px;
  font-size: 16px;
}

.accepted__types {
  color: #A8A8A8;
  font-size: 13px;
  line-height: 16.93px;
}
</style>
