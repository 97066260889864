<template>
  <div>
    <div v-show="addText && !showStyle">
      <div class="d-flex justify-space-between add__text-btn pa-3 my-5 tw-cursor-pointer" @click="addTextToShirt">
        <span>Add Text</span>
        <v-icon>mdi-chevron-right</v-icon>
      </div>
    </div>

      <div v-show="showStyle">
        <div class="my-6">
          <label>Edit Text</label>
          <v-textarea auto-grow rows="2"
                      row-height="20" @input="editText(text)" autofocus class="mt-3" id="edit-text" color="orange" dense v-model="text" outlined/>
        </div>

      <div class="my-6">
        <label>Font</label>
        <v-select class="mt-3" @change="styleText('fontFamily',font)"  v-model="font" outlined append-icon="mdi-chevron-down" dense :items="fonts"></v-select>
      </div>
      <div class="d-flex justify-space-between my-6">
        <div>
          <label>Colour</label>
          <div :style="{background: chosenColor}" class="color-picker" @click="hideColorCanvas = false">
            <input type="color" @input="styleText('fill',color)" @change="styleText('fill',color)" id="color" v-model="color">
          </div>
        </div>
      </div>
      <div class="my-4">
        <label>Size</label>
        <div>
          <v-select outlined   @change="styleText('fontSize',size)" v-model="size" dense append-icon="mdi-chevron-down" :items="sizes"/>
        </div>
      </div>
        <div class="my-4 alignments">
          <label>Alignment</label>
          <div class="alignments-tabs">
            <v-tabs v-model="d">
              <v-tab
                v-for="(tab, i) in alignments"
                @click="styleText('textAlign', tab.text.toLowerCase())"
                :key="i">
                <span v-html="tab.svg" />
              </v-tab>
            </v-tabs>
          </div>
        </div>
      <div class="my-4">
        <label>Letter Spacing</label>
        <div class="range__progress">
          <v-slider  @change="styleText('charSpacing',letterSpacing)"  @input="styleText('charSpacing',letterSpacing)" v-model="letterSpacing" color="#FF7A01" max="100" />
        </div>
      </div>
      <div class="my-4">
        <label>Rotate</label>
        <div class="range__progress">
          <v-slider @change="styleText('angle',rotate)"  v-model="rotate" color="#FF7A01" max="360" />
        </div>
      </div>
      </div>

<!--      <div class="d-flex my-5">-->
<!--        <v-btn depressed rounded color="primary" class="white&#45;&#45;text" @click="SET_BTN_IN_VIEW('')" :disabled="!isTextPresent">Save</v-btn>-->
<!--        <v-btn outlined rounded color="primary" class="ml-4" @click="cancelText">Cancel</v-btn>-->
<!--      </div>-->
    </div>
<!--  </div>-->
</template>

<script>
import TextIcon from "@/components/icons/TextIcon";
// import VueColorPicker from '@caohenghu/vue-colorpicker'
import Pickr from '@simonwep/pickr'
import {mapMutations, mapState} from "vuex";
export default {
  name: "NewAddText",
  components: {TextIcon},
  data: () => ({

    text:'Text',
    fonts: [
        'DM Sans',
        'Roboto',
        'San serif',
        'monospace',
        'IBM Plex Serif',
        'Arial',
        'ui-sans-serif',
        'system-ui',
        'New Times Roman',
        'Helvetica',
        'Times New Roman',
        'Georgia',
        // 'Cambria',
        // 'Times New Roman',
        // 'ui-monospace',
    ],
    hideColorCanvas: true,
    color:'',
    alignments: [
      {
        text: 'Left',
        svg: `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.04199 7.5918C4.04199 7.03951 4.48971 6.5918 5.04199 6.5918H19.042C19.5943 6.5918 20.042 7.03951 20.042 7.5918C20.042 8.14408 19.5943 8.5918 19.042 8.5918H5.04199C4.48971 8.5918 4.04199 8.14408 4.04199 7.5918Z" fill="#514A41"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.04199 17.5918C4.04199 17.0395 4.48971 16.5918 5.04199 16.5918H15.2284C15.7807 16.5918 16.2284 17.0395 16.2284 17.5918C16.2284 18.1441 15.7807 18.5918 15.2284 18.5918H5.04199C4.48971 18.5918 4.04199 18.1441 4.04199 17.5918Z" fill="#514A41"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.04199 12.5918C4.04199 12.0395 4.48971 11.5918 5.04199 11.5918H12.542C13.0943 11.5918 13.542 12.0395 13.542 12.5918C13.542 13.1441 13.0943 13.5918 12.542 13.5918H5.04199C4.48971 13.5918 4.04199 13.1441 4.04199 12.5918Z" fill="#514A41"/>
              </svg>`
      },
      {
        text: 'Center',
        svg: `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.375 7.5918C4.375 7.03951 4.82272 6.5918 5.375 6.5918H19.375C19.9273 6.5918 20.375 7.03951 20.375 7.5918C20.375 8.14408 19.9273 8.5918 19.375 8.5918H5.375C4.82272 8.5918 4.375 8.14408 4.375 7.5918Z" fill="#514A41"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.28125 17.5918C6.28125 17.0395 6.72897 16.5918 7.28125 16.5918H17.4677C18.02 16.5918 18.4677 17.0395 18.4677 17.5918C18.4677 18.1441 18.02 18.5918 17.4677 18.5918H7.28125C6.72897 18.5918 6.28125 18.1441 6.28125 17.5918Z" fill="#514A41"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.625 12.5918C7.625 12.0395 8.07272 11.5918 8.625 11.5918H16.125C16.6773 11.5918 17.125 12.0395 17.125 12.5918C17.125 13.1441 16.6773 13.5918 16.125 13.5918H8.625C8.07272 13.5918 7.625 13.1441 7.625 12.5918Z" fill="#514A41"/>
              </svg>`
      },
      {
        text: 'Right',
        svg: `<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.70898 7.5918C4.70898 7.03951 5.1567 6.5918 5.70898 6.5918H19.709C20.2613 6.5918 20.709 7.03951 20.709 7.5918C20.709 8.14408 20.2613 8.5918 19.709 8.5918H5.70898C5.1567 8.5918 4.70898 8.14408 4.70898 7.5918Z" fill="#514A41"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.52246 17.5918C8.52246 17.0395 8.97018 16.5918 9.52246 16.5918H19.7089C20.2612 16.5918 20.7089 17.0395 20.7089 17.5918C20.7089 18.1441 20.2612 18.5918 19.7089 18.5918H9.52246C8.97018 18.5918 8.52246 18.1441 8.52246 17.5918Z" fill="#514A41"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.209 12.5918C11.209 12.0395 11.6567 11.5918 12.209 11.5918H19.709C20.2613 11.5918 20.709 12.0395 20.709 12.5918C20.709 13.1441 20.2613 13.5918 19.709 13.5918H12.209C11.6567 13.5918 11.209 13.1441 11.209 12.5918Z" fill="#514A41"/>
              </svg>`
      }
    ]
  }),
  props:{
    addText:[Boolean],

  },

  watch:{
    selectedText(val){
      if(val){
        this.text=val.text
      }
    },

  },

  computed: {
    ...mapState('buildMerch',
      [
        'customText',
        'fontFamily',
        'letterAngle',
        'fontSize',
        'textColor',
        'sizes',
        'fontLetterSpacing',
        'selectedText',
        "canvas",
        "showStyle",
        "textAlign"
      ]
    ),

    font: {
      get () {
        return this.fontFamily
      },
      set (font) {
        this.UPDATE_STATE_PROPERTY({prop: 'fontFamily', value: font})
      }
    },
    size: {
      get () {
        return this.fontSize
      },
      set (size) {
        this.UPDATE_STATE_PROPERTY({prop: 'fontSize', value: size})
      }
    },
    letterSpacing: {
      get () {
        return this.fontLetterSpacing
      },
      set (letterSpacing) {
        this.UPDATE_STATE_PROPERTY({prop: 'fontLetterSpacing', value: letterSpacing})
      }
    },
    rotate: {
      get () {
        return this.letterAngle
      },
      set (rotate) {
        this.UPDATE_STATE_PROPERTY({prop: 'letterAngle', value: rotate})
      }
    },
    chosenColor () {
      return this.textColor
    },
    // color: {
    //   get () {
    //     return this.textColor
    //   },
    //   set (color) {
    //     this.$emit('styleText',color)
    //     // this.UPDATE_STATE_PROPERTY({prop: 'textColor', value: color})
    //   }
    // },
    isTextPresent () {
      return !!this.text
    },
    _textAlign: {
      get () {
        return this.textAlign
      },
      set (value) {
        this.UPDATE_STATE_PROPERTY({prop: 'textAlign', value})
      }
    }
  },
  methods: {
    ...mapMutations('buildMerch', ['UPDATE_STATE_PROPERTY']),
    ...mapMutations('buildMerch', ['SET_BTN_IN_VIEW']),

    changeColor(color) {
      const { r, g, b, a } = color.rgba
      this.UPDATE_STATE_PROPERTY({prop: 'textColor', value: `rgba(${r}, ${g}, ${b}, ${a})`})
    },
    addTextToShirt(){
      // this.addText = true
      this.$emit('insertText','Text')
      // this.text='Text'
    },
    editText(value){
      this.$emit('editText', value)

    },
    styleText(style,value){
      this.$emit('styleText',style,value)
    },
    cancelText(){
      this.$emit('editText', '')
      this.SET_BTN_IN_VIEW('')
      this.$emit('cancel')

    },


  },
}
</script>

<style lang="scss" scoped>
.add__text-btn {
  background: #FBFBFB;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20.83px;
  }
  .template{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #A8A8A8 !important;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.color__circle {
  border-radius: 100%;
  width: 32px;
  height: 32px;
}

label {
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

::v-deep {
  .alignments-tabs {
    padding: 4px;
    background: #F9F9F9;
    .v-tabs-slider-wrapper {
      height: 100% !important;
      z-index: -1 !important;

      .v-tabs-slider {
        background: #FFFFFF !important;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06) !important;
        border-radius: 4px !important;
      }
    }

    .theme--light.v-tabs > .v-tabs-bar {
      background: #F9F9F9;
    }

    .v-slide-group.v-item-group > .v-slide-group__next, .v-slide-group.v-item-group > .v-slide-group__prev {
      display: none !important;
    }

    .v-tab {
      max-width: 81px;
      height: 42px;

      &:before {
        display: none !important;
      }

      &:hover {
        background: transparent !important;
      }

      span.v-ripple__container {
        display: none !important;
      }
    }
  }
}
</style>
