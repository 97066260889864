<template>
  <div class="request-design-fill-details mx-auto mt-12 pb-3 md:tw-pr-8">
    <div>
      <svg width="41" height="20" viewBox="0 0 41 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.272461" y="0.36084" width="40" height="19" rx="9.5" fill="#9AF97C"/>
        <path d="M6.83646 14.3608V13.2088H7.96446V7.11284H6.83646V5.96084H11.0965C11.6005 5.96084 12.0405 6.04884 12.4165 6.22484C12.7925 6.39284 13.0845 6.63684 13.2925 6.95684C13.5085 7.26884 13.6165 7.64084 13.6165 8.07284V8.19284C13.6165 8.57684 13.5445 8.89284 13.4005 9.14084C13.2565 9.38884 13.0805 9.58084 12.8725 9.71684C12.6725 9.85284 12.4805 9.94884 12.2965 10.0048V10.1968C12.4805 10.2448 12.6765 10.3408 12.8845 10.4848C13.1005 10.6208 13.2805 10.8128 13.4245 11.0608C13.5765 11.3088 13.6525 11.6328 13.6525 12.0328V12.1528C13.6525 12.6168 13.5445 13.0128 13.3285 13.3408C13.1125 13.6688 12.8125 13.9208 12.4285 14.0968C12.0525 14.2728 11.6165 14.3608 11.1205 14.3608H6.83646ZM9.27246 13.1848H10.9645C11.3965 13.1848 11.7365 13.0808 11.9845 12.8728C12.2325 12.6648 12.3565 12.3768 12.3565 12.0088V11.9008C12.3565 11.5328 12.2325 11.2448 11.9845 11.0368C11.7445 10.8288 11.4045 10.7248 10.9645 10.7248H9.27246V13.1848ZM9.27246 9.53684H10.9645C11.3725 9.53684 11.7005 9.43684 11.9485 9.23684C12.1965 9.02884 12.3205 8.74884 12.3205 8.39684V8.27684C12.3205 7.92484 12.1965 7.64884 11.9485 7.44884C11.7085 7.24084 11.3805 7.13684 10.9645 7.13684H9.27246V9.53684ZM14.8655 14.3608V5.96084H20.1575V7.13684H16.1615V9.54884H19.8215V10.7248H16.1615V13.1848H20.2175V14.3608H14.8655ZM23.3326 14.3608V7.13684H20.7526V5.96084H27.2086V7.13684H24.6286V14.3608H23.3326ZM26.6873 14.3608L29.0873 5.96084H31.3673L33.7553 14.3608H32.4233L31.8713 12.3808H28.5833L28.0313 14.3608H26.6873ZM28.9073 11.1808H31.5473L30.3233 6.76484H30.1313L28.9073 11.1808Z" fill="#00050D"/>
      </svg>
      <h5>{{title}}</h5>
      <p>We would work on your design within 1-2 days.</p>
    </div>
    <div class="mt-10">
      <div v-if="_step === 1">
        <v-textarea outlined no-resize v-model="designText" counter="100" />
        <div class="mt-9">
          <div class="upload-label">Image Reference (Optional)</div>
          <drag-and-drop-uploader :handle-change="handleImageChange" show-preview />
        </div>
      </div>
      <div v-else-if="_step === 2">
        <div>
          <label>Name</label>
          <v-text-field v-model="merchName" outlined />
        </div>
        <div>
          <label>Merch</label>
          <v-select v-model="merch" return-object item-text="name" :items="products" outlined />
        </div>
        <div v-if="merch" class="mt-4">
          <chosen-product-overview :product-name="productName" :image="image" />
          <div style="width: 256px">
            <select-color-from-picker :colors="colors" class="mt-6" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DragAndDropUploader from "@/components/build/DragAndDropUploader";
import ChosenProductOverview from "@/components/build/ChosenProductOverview";
import image from "@/assets/shirts/small_sample_shirt.svg"
import SelectColorFromPicker from "@/components/build/SelectColorFromPicker";
export default {
  name: "RequestDesignFillDetails",
  components: { SelectColorFromPicker, ChosenProductOverview, DragAndDropUploader },
  data: () => ({
    designText: '',
    merchName: '',
    merch: {},
    products: []
  }),
  computed: {
    title () {
      return this._step === 1 ? 'What do you want designed on your merch?' : 'Choose Merch'
    },
    _step () {
      return this.step - 1
    },
    image () {
      return this.merch?.default_merch?.front ?? ""
    },
    colors () {
      return this.merch?.color_codes
    },
    productName () {
      return this.merch?.name
    }
  },
  props: {
    step: {
      type: Number
    }
  },
  watch: {
    merch: {
      deep: true,
      handler: function (merch) {
        this.onDataUpdated({label: 'selected_product',
          value: { id: merch.id, name: merch.name, front: merch?.default_merch?.front, back: this.merch?.default_merch?.back }})
      }
    },
    designText: {
      handler: function (value) {
        this.onDataUpdated({label: 'text', value})
      }
    },
    merchName: {
      handler: function (value) {
        this.onDataUpdated({label: 'merch_name', value})
      }
    }
  },
  async created () {
    const { data } = await this.$axios(`/products/product/`)
    console.log(data)
    this.products = data
  },
  methods: {
    onDataUpdated ({ label, value }) {
      this.$emit('update', { label, value })
    },
    handleImageChange (file) {
      this.onDataUpdated({label: 'design_print', value: file})
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  &.request-design-fill-details {
    max-width: 400px;
    height: calc(100% - 126px);
    overflow-y: scroll;

    @media (min-width: 767px) {
      height: calc(100% - 96px);
    }
  }

  .upload-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    color: #292929;
  }

  h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    /* or 100% */

    letter-spacing: -0.02em;

    /* Brown/500 */

    color: #514A41;
    margin-top: 10px;
  }

  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height */


    color: #808080;
    margin-top: 10px;
  }
}
</style>
