<template>
  <svg fill="none" height="25" viewBox="0 0 25 25" width="25" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M4.56055 16.2124L4.56055 17.2124C4.56055 18.8693 5.90369 20.2124 7.56055 20.2124L17.5605 20.2124C19.2174 20.2124 20.5605 18.8693 20.5605 17.2124L20.5605 16.2124M16.5605 12.2124L12.5605 16.2124M12.5605 16.2124L8.56055 12.2124M12.5605 16.2124L12.5605 4.2124"
        :stroke="active ? '#FF7A01' : '#514A41'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: "DownloadIcon",
  props: {
    active: Boolean
  }
}
</script>

<style scoped>

</style>